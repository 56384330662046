import Request, { RequestParams } from './request'
import single from '@/utils/getSingletonStore'

class OverviewService extends Request {
  constructor(props: RequestParams) {
    super(props)
  }

  getOverview() {
    return this.get<DIOX.Overview>('', { params: { module: 'chain', action: 'status' } })
  }
}

export default single<OverviewService>(OverviewService, { baseURL: '/api' })
